import React from 'react';
import Nav from "../components/NewNav"


const testpage2 = () => {
  return(
    
    <div>
       <Nav />
        <div>
          This is Page 2
        </div>
    </div>
    
  )
    
}

export default testpage2